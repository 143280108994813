// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "./Services.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper/modules";
import htmlToText from "Helpers/HtmlToText";

const ServicesFeature = ({ data = [] }) => {
  return (
    <div className={`relative `}>
      <Swiper
        slidesPerView={1}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: data?.list.length === 2 ? 2 : 3,
          },
        }}
        effect="fade"
        speed={1000}
        spaceBetween={60}
        className="catswipperslider "
        modules={[Navigation]}
        navigation={true}
      >
        {data?.list?.map(({ image, list, text }, index) => {
          const paragraphs = list?.split("</p>");
          return (
            <SwiperSlide key={index}>
              <div className="relative ">
                <img
                  src={image}
                  alt=""
                  className="max-h-[300px]  object-cover rounded-xl"
                />

                {text && (
                  <p className="mt-4 mb-2 text-2xl text-red">
                    {htmlToText(text)}
                  </p>
                )}

                <ul
                  className={`flex flex-col list-disc list-inside gap-y-1 ${
                    paragraphs?.filter((paragraph) => paragraph.trim() !== "")
                      .length > 6 && data?.list.length === 2
                      ? "grid grid-cols-2"
                      : ""
                  }`}
                >
                  {paragraphs
                    ?.filter((paragraph) => paragraph.trim() !== "")
                    ?.map((paragraph, index) => (
                      <li key={index} className="font-[400] text-sm">
                        {htmlToText(paragraph)}
                      </li>
                    ))}
                </ul>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default ServicesFeature;
