import { useState } from "react";
import MobileNav from "./MobileNav";
import "../../Header.css";

const MobileButton = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const handleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };
  return (
    <>
      <button
        onClick={handleMobileNav}
        className={`MobileBtnBox flex flex-col gap-y-1.5 xl:hidden ${
          isMobileNavOpen ? "isActive" : ""
        } `}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>

      <MobileNav
        handleMobileNav={handleMobileNav}
        isMobileNavOpen={isMobileNavOpen}
      />
    </>
  );
};

export default MobileButton;
