import Container from "Components/Container/Container";

const SuccessStoryBody = ({ data }) => {
  return (
    <section>
      <Container>
        <div className="flex flex-col  gap-14 my-secondary lg:my-primary">
          <div className="w-full">
            <img src={data.image} className="w-full" alt="" />
          </div>
          <div className="">
            <div className="lg:w-[80%]">
              <div className="border-b-2 border-[#bbb] pb-4">
                <h2 className="font-segu text-blue font-[600] text-xl sm:text-2xl md:text-3xl  mb-3">
                  {data?.mainTitle}
                </h2>
                <p>{data?.text}</p>
              </div>

              <div className="mt-4">
                <p className="font-segu text-xl sm:text-2xl md:text-3xl text-blue mb-2">
                  {data?.storyTitle}
                </p>
                <div className="mt-4">
                  {data?.contentSections.map(
                    ({ sectionTitle, sectionText }, index) => (
                      <div className="mb-6">
                        <h6 className="text-xl  md:text-2xl  text-red mb-1 font-segu">
                          {sectionTitle}
                        </h6>
                        {/* section text */}
                        {sectionText.map((text) => (
                          <ul
                            className="font-segu"
                            dangerouslySetInnerHTML={{ __html: text }}
                          />
                        ))}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SuccessStoryBody;
