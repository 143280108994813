import React from "react";
import htmlToText from "Helpers/HtmlToText";
const SolutionHero = ({ data }) => {
  return (
    <div className="lg:text-center lg:w-3/4 mx-auto font-segu mb-secondary">
      <h2 className="text-4xl text-red font-[500] ">{data.title}</h2>
      <p className="text-lightFont font-[500] mt-2 text-lg">
        {htmlToText(data.subtitle)}
      </p>
    </div>
  );
};

export default SolutionHero;
