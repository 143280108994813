import HeaderLinks from "Constant/Header";
import { Link } from "react-router-dom";
import { CaretDown, CaretRight } from "@phosphor-icons/react";
const DesktopNav = ({ isHome }) => {
  const links = HeaderLinks();

  const linkStyle = `relative px-6 py-5 font-segu font-[600] uppercase text-xs xl:text-sm mt-[1px] transition ease-in duration-100 hover:text-red  ${
    isHome ? "text-white" : "text-font"
  } flex items-center gap-x-1`;

  return (
    <ul className="flex items-center ">
      {links.map(({ name, url, mega, list, title, id, link }, index) =>
        mega ? (
          <button
            key={index}
            className={`relative py-5 px-6 font-segu font-[600] uppercase text-xs xl:text-sm mt-[1px] transition ease-in duration-100 hover:text-red  ${
              isHome ? "text-white" : "text-font"
            } flex items-center gap-x-1 group`}
          >
            {name}
            <span className={`${isHome ? "white " : "black"}`}>
              <CaretDown size={20} />
            </span>
            {mega && (
              <div
                className={`absolute w-full left-0 top-[100%] shadow-2xl  min-w-max h-auto transition ease-in duration-200 bg-gray-800 opacity-[0.90] font-segu p-4 opacity-0 invisible   translate-y-[10%] z-[-10] select-none group-hover:opacity-100 group-hover:visible group-hover:select-auto group-hover:scale-[1] group-hover:z-[100]  group-hover:translate-y-[0.5%]`}
              >
                <ul
                  className={
                    "flex flex-col gap-5 py-4 justify-between min-w-[150px]  w-full text-white"
                  }
                >
                  {list?.map((item, innderIndex) => (
                    <li key={innderIndex}>
                      {item.url ? (
                        <Link
                          to={item.url}
                          className=" flex items-center justify-between gap-x-2 text-white font-segu font-[500] w-full text-start hover:underline item-list transition ease-in duration-100 capitalize text-base  "
                        >
                          {item.name}
                          <span className="item-icon">
                            <CaretRight size={18} />
                          </span>
                        </Link>
                      ) : (
                        <a
                          target="_blank"
                          className=" item-list flex items-center justify-between gap-x-2 text-white font-segu font-[500] w-full text-start hover:underline  transition ease-in duration-100 capitalize text-base "
                          href={item.link}
                          rel="noreferrer"
                        >
                          {item.name}
                          <span className="item-icon">
                            <CaretRight size={18} />
                          </span>
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </button>
        ) : (
          <Link key={index} className={linkStyle} to={url}>
            {name}
          </Link>
        )
      )}
    </ul>
  );
};

export default DesktopNav;
