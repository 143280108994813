import React from "react";
import htmlToText from "Helpers/HtmlToText";

const ModalResult = ({ data }) => {
  return (
    <div className=" text-white font-segu">
      <h2 className="text-3xl font-[700]">The end result?</h2>

      <div className="grid grid-cols-1 ss:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-10 md:gap-10 lg:gap-6 mt-6">
        {data?.map(({ text }, index) => (
          <div
            key={index}
            className="bg-lightBlue w-52 h-52 ss:w-44 ss:h-44 lg:h-52 lg:w-52 rounded-full flex items-center justify-center text-center p-6 border-4 border-[#0e4381]"
          >
            <p className="text-white">{htmlToText(text)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModalResult;
