import { SealWarning } from "@phosphor-icons/react";
import Container from "Components/Container/Container";
const IsError = ({ iswhite }) => {
  return (
    <div className=" bg-gray-400 gap-x-4 h-[100vh] ">
      <Container className={"flex items-center justify-center h-full"}>
        <SealWarning size={32} weight="light" color="#d92128" />
        <p className={`ml-4 font-segu ${iswhite ? "text-white" : ""}`}>
          Unable to retrieve data. <br />
          Please try again later.
        </p>
      </Container>
    </div>
  );
};

export default IsError;
