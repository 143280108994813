import React, { useRef, useEffect, useState } from "react";
import htmlToText from "Helpers/HtmlToText";
import { useParams } from "react-router-dom";
import Container from "Components/Container/Container";
const AboutTheServices = ({ data }) => {
  const [textHeight, setTextHeight] = useState(0);
  const textRef = useRef(null);
  const { slug } = useParams();

  useEffect(() => {
    if (textRef.current) {
      setTextHeight(textRef.current.clientHeight);
    }
  }, [data.description]);

  const contentHeight = textHeight > 400 ? textHeight : 400;

  return (
    <section>
      <div className="flex flex-col xl:flex-row lg:gap-8 py-6 md:py-secondary">
        <Container>
          <div
            ref={textRef}
            className="flex-1 text-segu flex justify-center flex-col  py-6 text-center items-center"
          >
            <div className="">
              <h4 className="text-2xl font-[700] mb-3">{data.subtitle}</h4>
              <p>{htmlToText(data.description)}</p>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default AboutTheServices;
