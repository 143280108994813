import React from "react";
import fixImageUrl from "Helpers/FixImageUrl";
import Container from "Components/Container/Container";
import Button from "Components/Buttons/Button";

const Hero = ({ data, tabsData, selectedTab, onHandleTabs }) => {
  const image = fixImageUrl(data.image);

  return (
    <section
      className="pt-44 background-class"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="h-full flex flex-col justify-center">
        <Container className={" flex-1"}>
          <div
            className={
              "h-full flex flex-col justify-center text-white font-segu lg:w-1/2"
            }
          >
            <h1 className="text-3xl font-[700]">{data.title}</h1>

            <p className="mt-3">
              Unleash the power of the cloud: explore how it can transform your
              industry, improve efficiency and agility, reduce costs, and
              empower you to participate in groundbreaking business models and
              capture exciting market opportunities.
            </p>

            <div className="mt-6">
              <Button to={"/contact"}>Send inquiry</Button>
            </div>
          </div>
        </Container>
        <div className="bg-[#00000056] backdrop-blur-sm text-white mt-12 border-t border-[#ccc]">
          <Container className="flex items-center justify-between gap-4  mt-auto overflow-scroll lg:overflow-hidden">
            {tabsData.map(({ title }, index) => (
              <button
                onClick={() => onHandleTabs(title)}
                className={`font-medium  py-8 flex-1 min-w-[fit-content] transition ease-in duration-300 text-center px-2 ${
                  selectedTab === title ? "bg-white text-black" : ""
                }`}
                key={index}
              >
                {title}
              </button>
            ))}
          </Container>
        </div>
      </div>
    </section>
  );
};

export default Hero;
