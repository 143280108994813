import Container from "Components/Container/Container";
import htmlToText from "Helpers/HtmlToText";

const PartnersLanding = ({ data }) => {
  return (
    <section className="py-secondary border-b-[6px] border-gray-200">
      <Container>
        <div className="flex flex-col gap-y-10">
          {data?.map(({ image, title, description }, index) => (
            <div
              key={index}
              className="flex flex-col lg:flex-row gap-6 lg:gap-16 lg:items-center"
            >
              <div className="w-40 h-40">
                <img
                  src={image}
                  alt="company-log"
                  className="w-40 h-40 object-contain"
                />
              </div>
              <div className="lg:border-l lg:border-lightFont lg:px-10 lg:w-3/4 font-segu ">
                <h2 className="text-2xl font-[600] text-black mb-1">{title}</h2>
                <p>{htmlToText(description)}</p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default PartnersLanding;
