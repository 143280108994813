import fixImageUrl from "Helpers/FixImageUrl";
import Container from "Components/Container/Container";

import ModalContent from "./Components/ModalContent";
import ModalResult from "./Components/ModalResult";
const Modal = ({ data }) => {
  const { title, description, image, result } = data;
  const fixedImage = fixImageUrl(image);

  const contentData = {
    title,
    description,
  };
  return (
    <section
      className="full-background-class py-32"
      style={{ backgroundImage: `url(${fixedImage})` }}
    >
      <Container className={"flex flex-col gap-y-32 "}>
        <ModalContent data={contentData} />
        <ModalResult data={result} />
      </Container>
    </section>
  );
};

export default Modal;
