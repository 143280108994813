import Container from "Components/Container/Container";
import htmlToText from "Helpers/HtmlToText";
import React, { useLayoutEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./style.css";
import { Autoplay, Navigation } from "swiper/modules";
import { CaretRight, CaretLeft } from "@phosphor-icons/react";
const OurPortfolio = ({ data }) => {
  const slideRefs = useRef([]);
  const [maxHeight, setMaxHeight] = useState(0);
  const [swiper, setSwiper] = useState(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useLayoutEffect(() => {
    // Ensure slideRefs.current is populated
    if (
      slideRefs.current.length === 0 ||
      slideRefs.current.every((ref) => ref === null)
    ) {
      return;
    }

    // Calculate the maximum height after the DOM is fully rendered
    const heights = slideRefs.current.map((slide) => {
      if (slide) {
        return slide.offsetHeight;
      }
      return 0;
    });

    const maxHeight = Math.max(...heights);

    setMaxHeight(maxHeight);
  }, [data]);

  return (
    <section className="my-primary ">
      <Container className="relative">
        <h1 className="lg:text-center font-segu font-[700] text-3xl mb-secondary">
          {data.title}
        </h1>
        <div className="relative">
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            slidesPerGroup={1}
            navigation={{
              clickable: true,
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
            }}
            breakpoints={{
              420: {
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
              768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
              992: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
            }}
            modules={[Autoplay, Navigation]}
            className="portfolio-slider "
            speed={1000}
          >
            {data?.list?.map(({ id, title, text }, index) => (
              <SwiperSlide
                className="max-w-3/4 w-3/4 bg-gray-200 p-4 rounded-2xl transition-bg ease-in duration-300 hover:bg-[#c9041b62]"
                key={index}
                ref={(el) => {
                  slideRefs.current[index] = el;
                }}
                style={{
                  height: maxHeight ? `${maxHeight}px` : "auto",
                }}
              >
                <p className="font-bold mb-3">{title}</p>
                {text && <p>{htmlToText(text)}</p>}
              </SwiperSlide>
            ))}
          </Swiper>

          <button
            ref={prevRef}
            className="absolute top-1/2 -translate-y-1/2 -left-8 z-[100] solution-prev-button flex items-center justify-center "
          >
            <CaretLeft />
          </button>
          <button
            ref={nextRef}
            className="absolute top-1/2 -translate-y-1/2 -right-8 z-[100] solution-next-button flex items-center justify-center "
          >
            <CaretRight />
          </button>
        </div>
      </Container>
    </section>
  );
};

export default OurPortfolio;
