import React from "react";
import Container from "Components/Container/Container";

import LatestNewsSlider from "./LatestNewsSlider";

const LatestNews = ({ data }) => {
  return (
    <div className="py-20  w-full bg-gray-300">
      <Container className="h-full flex flex-col gap-y-4 justify-center">
        <div className=" flex flex-col justify-center font-segu text-white">
          <h1 className="text-4xl font-[700]">Media</h1>
          <p className="text-lg">Our Latest Articles and News</p>
        </div>

        <LatestNewsSlider data={data} />
      </Container>
    </div>
  );
};

export default LatestNews;
