import React from "react";
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";
import Container from "Components/Container/Container";

const Security = () => {
  const { data, error, loading } = useFetch("privacy-policy");

  if (loading) return <IsLoading />;
  if (error) return <IsError />;

  if (data) {
    return (
      <section className="py-32">
        <Container>
          <div>
            <h1 className="text-3xl text-red mb-6 font-bold">
              {data?.privacyData?.list?.title}
            </h1>
            <div
              className="custom-content text-lg"
              dangerouslySetInnerHTML={{
                __html: data?.privacyData?.list?.description,
              }}
            />
          </div>
        </Container>
      </section>
    );
  }

  return null; // fallback in case no data is returned
};

export default Security;
