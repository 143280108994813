import Input from "form/Inputs/Input";
import useInput from "form/Hooks/user-input";
import Textarea from "form/Inputs/Textarea";
import SelectInput from "form/Inputs/SelectInput";

import Container from "Components/Container/Container";
import { useState } from "react";
const Form = () => {
  const {
    value: salutationInput,
    isValid: salutationIsValid,
    HasError: salutationHasError,
    inputChangeHandler: salutationChangeHandler,
    inputBlurHandler: salutationBlurHandler,
    inputFocusHandler: salutationFocusHanlder,
    isFocus: salutationIsFocus,
  } = useInput((value) => value.trim() !== "");
  const {
    value: firstNameInput,
    isValid: firstNameIsValid,
    HasError: firstNameHasError,
    inputChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    inputFocusHandler: firstNameFocusHanlder,
    isFocus: firstNameIsFocus,
  } = useInput((value) => value.trim() !== "");
  const {
    value: lastNameInput,
    isValid: lastNameIsValid,
    HasError: lastNameHasError,
    inputChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    inputFocusHandler: lastNameFocusHanlder,
    isFocus: lastNameIsFocus,
  } = useInput((value) => value.trim() !== "");

  const {
    value: companyNameInput,
    isValid: companyNameIsValid,
    HasError: companyNameHasError,
    inputChangeHandler: companyNameChangeHandler,
    inputBlurHandler: companyNameBlurHandler,
    inputFocusHandler: companyNameFocusHanlder,
    isFocus: companyNameIsFocus,
  } = useInput((value) => value.trim() !== "");

  const {
    value: phoneNumberInput,
    isValid: phoneNumberIsValid,
    HasError: phoneNumberHasError,
    inputChangeHandler: phoneNumberChangeHandler,
    inputBlurHandler: phoneNumberBlurHandler,
    inputFocusHandler: phoneNumberFocusHanlder,
    isFocus: phoneNumberIsFocus,
  } = useInput((value) => value.trim() !== "");

  const {
    value: emailInput,
    isValid: emailIsValid,
    HasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    inputFocusHandler: emailFocusHanlder,
    isFocus: emailIsFocus,
  } = useInput((value) => value.trim() !== "");

  const {
    value: areaOfIntersetInput,
    isValid: areaOfIntersetIsValid,
    HasError: areaOfIntersetHasError,
    inputChangeHandler: areaOfIntersetChangeHandler,
    inputBlurHandler: areaOfIntersetBlurHandler,
    inputFocusHandler: areaOfIntersetFocusHanlder,
    isFocus: areaOfIntersetIsFocus,
  } = useInput((value) => value.trim() !== "");

  const {
    value: hearAboutUsInput,
    isValid: hearAboutUsIsValid,
    HasError: hearAboutUsHasError,
    inputChangeHandler: hearAboutUsChangeHandler,
    inputBlurHandler: hearAboutUsBlurHandler,
    inputFocusHandler: hearAboutUsFocusHanlder,
    isFocus: hearAboutUsIsFocus,
  } = useInput((value) => value.trim() !== "");

  const {
    value: messageInput,
    isValid: messageIsValid,
    HasError: messageHasError,
    inputChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHandler,
    inputFocusHandler: messageFocusHanlder,
    isFocus: messageIsFocus,
  } = useInput((value) => value.trim() !== "");

  const subjectOption = [
    "Website Feedback",
    " Product or Service Inquiries",
    "Customer Support",
    "Partnership Opportunities",
    "Employment Opportunities",
    "Complaints",
    "Suggestions",
    "Other",
  ];
  const [subjectInput, setSubjectInput] = useState("");
  const subjectChangeHandler = (e) => {
    setSubjectInput(e.target.value);
  };

  return (
    <div className="pt-1 pb-primary bg-gray-300  mt-secondary  font-segu font-[600]">
      <Container>
        <h2 className="text-3xl font-segu font-bold mt-[7rem] text-center mb-secondary">
          Contact Form
        </h2>

        <div className="flex flex-col gap-y-4">
          <span className="flex flex-col lg:flex-row items-center gap-x-4">
            <Input
              type={"text"}
              label={"salutation"}
              name={" salutation"}
              id={"salutation"}
              value={salutationInput}
              onChange={(event) => {
                salutationChangeHandler(event);
              }}
              onBlur={salutationBlurHandler}
              onFocus={salutationFocusHanlder}
              isFocus={salutationIsFocus}
              hasError={salutationHasError}
              errorMessage={"Salutation is required"}
            />
            <Input
              type={"text"}
              label={"first name"}
              name={"firstName"}
              id={"firstName"}
              value={firstNameInput}
              onChange={(event) => {
                firstNameChangeHandler(event);
              }}
              onBlur={firstNameBlurHandler}
              onFocus={firstNameFocusHanlder}
              isFocus={firstNameIsFocus}
              hasError={firstNameHasError}
              errorMessage={"first name  is required"}
            />
            <Input
              type={"text"}
              label={"last name"}
              name={"lastName"}
              id={"lastName"}
              value={lastNameInput}
              onChange={(event) => {
                lastNameChangeHandler(event);
              }}
              onBlur={lastNameBlurHandler}
              onFocus={lastNameFocusHanlder}
              isFocus={lastNameIsFocus}
              hasError={lastNameHasError}
              errorMessage={"last name  is required"}
            />
          </span>

          <span className="flex flex-col lg:flex-row items-center gap-x-4">
            <Input
              type={"text"}
              label={"CompanyName"}
              name={"companyName"}
              id={"companyName"}
              value={companyNameInput}
              onChange={(event) => {
                companyNameChangeHandler(event);
              }}
              onBlur={companyNameBlurHandler}
              onFocus={companyNameFocusHanlder}
              isFocus={companyNameIsFocus}
              hasError={companyNameHasError}
              errorMessage={"company name is required"}
            />

            <Input
              type={"number"}
              label={"Phone Number"}
              name={"phoneNumber"}
              id={"phoneNumber"}
              value={phoneNumberInput}
              onChange={(event) => {
                phoneNumberChangeHandler(event);
              }}
              onBlur={phoneNumberBlurHandler}
              onFocus={phoneNumberFocusHanlder}
              isFocus={phoneNumberIsFocus}
              hasError={phoneNumberHasError}
              errorMessage={"Phone number is required"}
            />

            <Input
              type={"email"}
              label={"Work Email"}
              name={" email"}
              id={"email"}
              value={emailInput}
              onChange={(event) => {
                emailChangeHandler(event);
              }}
              onBlur={emailBlurHandler}
              onFocus={emailFocusHanlder}
              isFocus={emailIsFocus}
              hasError={emailHasError}
              errorMessage={"email is required"}
            />
          </span>

          <span className="flex flex-col lg:flex-row items-center gap-x-4">
            <Input
              type={"text"}
              label={"Area of Interest"}
              name={"areaOfInterset"}
              id={"areaOfInterset"}
              value={areaOfIntersetInput}
              onChange={(event) => {
                areaOfIntersetChangeHandler(event);
              }}
              onBlur={areaOfIntersetBlurHandler}
              onFocus={areaOfIntersetBlurHandler}
              isFocus={areaOfIntersetIsFocus}
              hasError={areaOfIntersetHasError}
              errorMessage={"this field is required"}
            />
            <Input
              type={"text"}
              label={"  How did you hear about us"}
              name={"hearAboutUs"}
              id={"hearAboutUs"}
              value={hearAboutUsInput}
              onChange={(event) => {
                hearAboutUsChangeHandler(event);
              }}
              onBlur={hearAboutUsBlurHandler}
              onFocus={hearAboutUsFocusHanlder}
              isFocus={hearAboutUsIsFocus}
              hasError={hearAboutUsHasError}
              errorMessage={"this field is required"}
            />

            <SelectInput
              label={"Subject"}
              name={"Subject"}
              id={"Subject"}
              options={subjectOption}
              value={subjectInput}
              onChange={subjectChangeHandler}
            />
          </span>

          <Textarea
            type={"text"}
            label={"Your Message"}
            name={"your-message"}
            id={"your-message"}
            value={messageInput}
            onChange={(event) => {
              messageChangeHandler(event);
            }}
            onBlur={messageBlurHandler}
            onFocus={messageFocusHanlder}
            isFocus={messageIsFocus}
            hasError={messageHasError}
            errorMessage={"Message  is required"}
          />

          <button className="bg-black text-white font-segu w-max mx-auto px-6 py-2.5 rounded-md">
            Submit
          </button>
        </div>
      </Container>
    </div>
  );
};

export default Form;
