import LatestNews from "./Sections/LatestNews";
import NewsLanding from "./Sections/NewsLanding";

// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

const News = () => {
  const { data, loading, error } = useFetch("news");

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  else if (data) {
    const sortedNewsData = data?.NewsData?.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );

    const latestThreeNews = sortedNewsData.slice(0, 3); // Retrieve the latest 3 news

    return (
      <main>
        <LatestNews data={latestThreeNews} />
        <NewsLanding data={data?.NewsData} />
      </main>
    );
  }
};

export default News;
