import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "Components/Buttons/Button";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./Hero.css";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";

const Hero = ({ data }) => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const handleSlideChange = () => {
      setActiveIndex(swiperInstance.realIndex);
    };

    if (swiperInstance) {
      swiperInstance.on("slideChange", handleSlideChange);
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", handleSlideChange);
      }
    };
  }, [swiperInstance]);

  return (
    <section className="">
      <Swiper
        onSwiper={setSwiperInstance}
        modules={[Autoplay, Pagination]}
        speed={1000}
        loop={true}
        pagination={{
          clickable: true,
        }}
        className="heroSlider"
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
      >
        {data?.map((cont, indx) => (
          <SwiperSlide key={indx} className="heroSlider-slider-parent">
            <div className="relative content-parent">
              <div className={`image-parent relative zoom `}>
                <img
                  src={cont.image}
                  className={`image-parent hero-image zoom ${
                    activeIndex === indx ? "zoomed" : ""
                  }`}
                  alt=""
                />
              </div>
              <div className="absolute  left-0 top-1/2 -translate-y-1/2 left-container lg:w-3/4 xl:w-1/2">
                <h1 className="text-3xl sm:text-4xl md:text-5xl segu-bold  text-white relative z-[1000] mb-6">
                  {cont.title}
                  {/* Meet Windows 11 */}
                </h1>

                <Button to={"/about"}>Learn More</Button>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Hero;
