import React from "react";
import fixImageUrl from "Helpers/FixImageUrl";
import Container from "Components/Container/Container";
const Hero = ({ data }) => {
  const image = fixImageUrl(data.image);
  return (
    <section
      style={{ backgroundImage: `url(${image})` }}
      className="background-class h-[80vh] "
    >
      <Container className={"h-full "}>
        <div className="flex flex-col justify-center text-white font-segu h-full lg:w-1/2">
          <h1 className="font-[900] text-2xl lg:text-4xl mb-3">{data.title}</h1>
          <p className="text-lg lg:text-2xl font-seguLight font-[300]">
            {data.subtitle}
          </p>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
