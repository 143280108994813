import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "./LatestNews.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Navigation } from "swiper/modules";

// icon
import { ArrowCircleRight } from "@phosphor-icons/react";

const LatestNewsSlider = ({ data = [] }) => {
  console.log(data);
  return (
    <Swiper
      navigation={true}
      spaceBetween={20}
      grabCursor={true}
      speed={800}
      modules={[Navigation]}
      className="LatesNewsSlider bg-white rounded-xl"
    >
      {data?.map(({ name, date, image, slug }, index) => (
        <SwiperSlide key={index} className="p-6">
          <div className="flex flex-col md:flex-row items-center gap-6 rounded-md  max-w-[92%] mx-auto">
            <div className="flex-1">
              <img
                className="min-h-[400px] object-cover rounded-md"
                src={image}
                alt=""
              />
            </div>
            <div className="flex-1 font-segu">
              <p className="text-gray-600">{date}</p>
              <h2 className="text-red font-medium text-2xl mb-2">{name}</h2>
              <span className="flex items-center gap-x-2 mt-small">
                <ArrowCircleRight size={26} weight="fill" color="#c9041a" />

                <Link className="text-sm text-[#333]" to={slug}>
                  Read More
                </Link>
              </span>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default LatestNewsSlider;
