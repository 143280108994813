import React from "react";

const SelectInput = (props) => {
  const {
    label,
    options,
    name,
    id,
    placeholder,
    value,
    onChange = () => {},
    onBlur = () => {},
    hasError = false,
    errorMessage = "error",
  } = props;
  return (
    <span className="flex flex-col w-full">
      <label className="text-sm font-segu font-[700] capitalize">{label}</label>

      <select
        // multiple
        className="bg-gray-200  font-[700]  font-segu  text-sm px-2 py-3 rounded-sm "
        onChange={onChange}
        name={name}
        value={value}
        id={id}
      >
        {options?.map((option, index) => (
          <option className="text-sm" key={index} value={option.value}>
            {option}
          </option>
        ))}
      </select>

      <p
        className={`text-xs text-red  ${hasError ? "opacity-1 " : "opacity-0"}`}
      >
        {errorMessage}
      </p>
    </span>
  );
};

export default SelectInput;
