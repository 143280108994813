import React, { useState, useEffect } from "react";
import Hero from "./Sections/Hero";
import IndustrySolutionLanding from "./Sections/IndustrySolutionLanding";
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

import "../IndustrySolution/Sections/Style.css";

const IndustrySolution = () => {
  const { data, loading, error } = useFetch("industry-solutions");

  const [selectedTab, setSelectedTab] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [transitionClass, setTransitionClass] = useState("fade-enter-active");

  const handleSelectTab = (tab) => {
    setTransitionClass("fade-exit-active");
    setTimeout(() => {
      setSelectedTab(tab);
      setTransitionClass("fade-enter-active");
    }, 300); // Match the transition duration in the CSS
  };

  useEffect(() => {
    if (data) {
      const defaultTab = data.industrylist.Solutionlist[0].title;
      setSelectedTab(defaultTab);
    }
  }, [data]);

  useEffect(() => {
    if (data && selectedTab) {
      const filteredData = data.industrylist.Solutionlist.filter(
        (item) => item.title === selectedTab
      );
      setSelectedData(filteredData);
    }
  }, [data, selectedTab]);

  if (loading) return <IsLoading />;
  if (error) return <IsError />;

  if (data) {
    const industrySolutionData = data?.industrylist;
    const heroData = industrySolutionData?.hero;
    const solutionListData = industrySolutionData?.Solutionlist;

    return (
      <main>
        <Hero
          selectedTab={selectedTab}
          onHandleTabs={handleSelectTab}
          tabsData={solutionListData}
          data={heroData}
        />
        <div className={`transition-container ${transitionClass}`}>
          <IndustrySolutionLanding data={selectedData} />
        </div>
      </main>
    );
  }

  return null;
};

export default IndustrySolution;
