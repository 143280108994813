import DefaultSlider from "Components/Sliders/DefaultSlider";
import Container from "Components/Container/Container";

const PartnerSlider = ({ data }) => {
  return (
    <section className="mt-secondary lg:mt-primary xxl:mt-xlPrimary">
      <Container>
        <h2 className="text-2xl sm:text-3xl font-[700] text-lightFont mb-6 ">
          Our Technology Partners
        </h2>
        <DefaultSlider data={data.list} />
      </Container>
    </section>
  );
};

export default PartnerSlider;
