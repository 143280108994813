import { ContactUs } from "Constant/Footer";
import { Link } from "react-router-dom";

const ContactUsCol = ({ colStyle }) => {
  const locationHandler = (location) => {
    window.open(`${"https://maps.app.goo.gl/NkRhBtjmcv59YHqe7"}`, "_blank");
  };
  const links = ContactUs();

  return (
    <div className={`${colStyle} `}>
      <Link to={"/contact"} className="text-red font-[600] ">
        Contact us
      </Link>

      {links?.map(
        ({ mega, list }, index) =>
          mega && (
            <div
              key={index}
              className="flex flex-col gap-y-1.5 text-sm font-segu"
            >
              {list?.map(
                ({ address, street, phone, email, location }, innerIndex) => (
                  <div
                    key={innerIndex}
                    className="first:mb-4 flex flex-col gap-y-1"
                  >
                    <p className="font-[700]">{address}</p>

                    <a
                      href={location?.lat}
                      target="_blank"
                      without
                      rel="noreferrer"
                      className="hover:underline"
                    >
                      {street}
                    </a>
                    {phone && (
                      <a
                        className="hover:underline"
                        href={`tel:${phone.trim()}`}
                      >
                        tel: {phone}
                      </a>
                    )}
                    {email && (
                      <a className="hover:underline " href={`mailto:${email}`}>
                        email: <span className="lowercase">{email}</span>
                      </a>
                    )}
                  </div>
                )
              )}
            </div>
          )
      )}
    </div>
  );
};

export default ContactUsCol;
