import Container from "Components/Container/Container";

// inner components
import FooterLogo from "./Columns/FooterLogo";
import LinkCol from "./Columns/LinkCol";
import SolutionCol from "./Columns/SolutionCol";
import ServicesCol from "./Columns/ServicesCol";
import IndustrySolutionCol from "./Columns/IndustrySolutionCol";
import ContactUsCol from "./Columns/ContactUsCol";

const Footer = () => {
  const colStyle = "flex flex-col font-segu gap-y-3";
  return (
    <footer className="border-t-[10px] box-shadow border-red">
      <Container>
        <FooterLogo />
        <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 justify-between w-full gap-10 mt-secondary mb-primary capitalize">
          <LinkCol colStyle={colStyle} />
          <ServicesCol colStyle={colStyle} />
          <SolutionCol colStyle={colStyle} />
          <IndustrySolutionCol colStyle={colStyle} />
          <ContactUsCol colStyle={colStyle} />
        </div>
      </Container>
      <div className="bg-gray-300 py-inner">
        <Container>
          <div className="flex flex-col items-center justify-center text-center font-segu text-light">
            <p>© 2024 Liveroute. All Rights Reserved</p>
            <p>
              By{" "}
              <a
                target={"_blank"}
                className="underline"
                href="https://www.dowgroup.com/"
              >
                {" "}
                Dow Group.
              </a>
            </p>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
