import React from "react";
import FeatureMobile from "./FeatureHero/FeatureMobile";

const Features = ({ data }) => {
  return (
    <section className="relative">
      <FeatureMobile data={data} />
    </section>
  );
};

export default Features;
