import React from "react";
import { Link } from "react-router-dom";

const Button = ({ children, to }) => {
  return (
    <Link
      to={to}
      className="block w-max rounded-[22px] bg-black text-white border-2 border-red relative  after:transition-translate after:ease-in after:z-[-1] z-[10] after:duration-300 overflow-hidden after:absolute after:bg-red after:w-full after:h-full after:left-0 after:top-0 after:translate-y-[100%] hover:after:translate-y-0 uppercase text-sm"
    >
      <span className="px-7 py-2.5 block">{children}</span>
    </Link>
  );
};

export default Button;
