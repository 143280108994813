import Container from "Components/Container/Container";
import Button from "Components/Buttons/Button";
const Hero = ({ data }) => {
  return (
    <section
      className="h-[80vh] background-class"
      style={{ backgroundImage: `url(${data.image})` }}
    >
      <Container className="h-full">
        <div className="h-full flex flex-col  justify-center lg:w-1/2 font-segu text-white">
          <h1 className="text-3xl font-[700] ">{data?.title}</h1>
          <p className="text-4xl lg:text-5xl leading-[1.2] mt-3 font-[300] font-light">
            {data?.subtitle}
          </p>

          <div className="mt-6">
            <Button to={"/contact"}>Send inquiry</Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
