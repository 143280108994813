import Hero from "./Sections/Hero";
import PartnersLanding from "./Sections/PartnersLanding";
import OtherPartnersSlider from "./Sections/OtherPartnersSlider";
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

const Partners = () => {
  const { data, loading, error } = useFetch("partners");
  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  if (data) {
    const heroData = data?.hero;
    const partnersListData = data?.list;
    const otherPartnersData = data?.otherpartner;
    return (
      <main>
        <Hero data={heroData} />
        <PartnersLanding data={partnersListData} />
        {/* <OtherPartnersSlider data={otherPartnersData} /> */}
      </main>
    );
  }
};

export default Partners;
