import { Link } from "react-router-dom";
import React, { useRef } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./Solutions.css";

// import required modules
import { Autoplay, Navigation } from "swiper/modules";

const SolutionSlider = ({ data }) => {
  return (
    <div className="relative">
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        slidesPerGroup={1}
        breakpoints={{
          420: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          578: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          768: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
          992: {
            slidesPerView: 5,
            slidesPerGroup: 2,
          },

          1920: {
            slidesPerView: 8,
            slidesPerGroup: 2,
          },
          2560: {
            slidesPerView: 10,
            slidesPerGroup: 2,
          },
        }}
        modules={[Autoplay, Navigation]}
        navigation={true}
        className="solution-slider"
        // loop={true}
        effect="fade"
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        speed={1000}
      >
        {data?.map(({ title, icon, slug }, index) => (
          <SwiperSlide key={index}>
            <Link
              to={`/solutions/${slug}`}
              className="flex items-center justify-center flex-col gap-y-3 mx-2"
            >
              <div className="w-24 h-24 mx-auto bg-white p-5 flex items-center justify-center rounded-full">
                <img
                  src={icon}
                  alt=""
                  className="h-full w-full object-contain"
                />
              </div>
              <div>
                <p className="font-segu font-[600] text-lightFont text-center text-sm">
                  {title}
                </p>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SolutionSlider;
