import {
  InstagramLogo,
  FacebookLogo,
  LinkedinLogo,
  TwitterLogo,
} from "@phosphor-icons/react";

const ContactInfro = ({ isHome, isScroll }) => {
  const iconStyle = {
    size: 18,
    color: `${isHome ? "black" : "white"}`,
  };

  const iconsBoXStyle = `${
    isHome ? "bg-white" : "bg-black"
  } p-[4px] rounded-full`;
  return (
    <div
      className={` items-center justify-end gap-x-3 ${
        isScroll ? "hidden" : "flex"
      }`}
    >
      <a
        className={iconsBoXStyle}
        rel="noreferrer"
        target="_blank"
        href="https://www.linkedin.com/company/liveroute/"
      >
        <LinkedinLogo size={iconStyle.size} color={iconStyle.color} />
      </a>
    </div>
  );
};

export default ContactInfro;
