import { Link } from "react-router-dom";
import logo from "Asset/images/logos/logo.png";
import FooterLogo from "Asset/images/logos/logo footer.png";

const Logo = ({ isHome, isScroll }) => {
  return (
    <div className="w-44 relative z-[10000]">
      <Link to="/">
        <img
          className={`${isScroll ? "w-36" : "w-40"} object-contain`}
          src={`${isHome ? logo : FooterLogo}`}
          alt="logo"
        />
      </Link>
      <div>
        <a
          target="_blank"
          className={` ${
            isScroll ? "text-xs" : "text-xs"
          } font-segu  text-[#8e8a97] font-[600] mt-2 hover:underline block `}
          href="https://www.midisgroup.com/"
          rel="noreferrer"
        >
          Part of MDS SI Group
        </a>
      </div>
    </div>
  );
};

export default Logo;
