import { Link } from "react-router-dom";
import { Industry_solution } from "Constant/Footer";
import { Fragment } from "react";
const IndustrySolutionCol = ({ colStyle }) => {
  const links = Industry_solution();
  return (
    <div className={colStyle}>
      {links?.map(({ name, mega, list, url }, index) => (
        <Fragment key={index}>
          <Link
            to={"/industry_solution"}
            className="text-red font-[600] hover:underline "
          >
            {name}
          </Link>
          {mega && (
            <div className="flex flex-col gap-y-1.5 text-sm">
              {list?.map(({ title }, index) => (
                <Link
                  key={index}
                  to={"/industry_solution"}
                  className="text-[#4A4A4A] font-[400] hover:underline"
                >
                  {title}
                </Link>
              ))}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default IndustrySolutionCol;
