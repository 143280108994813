import React, { useEffect } from "react";
import Container from "Components/Container/Container";
// import "./Style.css";

const LinkedIn = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.defer = true;
    script.dataset.useServiceCore = true;

    const div = document.createElement("div");
    div.className = "elfsight-app-e941c88d-062a-4b5b-b579-264a4413f0d6";

    const container = document.getElementById("elfsight-container");
    container.appendChild(script);
    container.appendChild(div);

    return () => {
      // Clean up the script and div elements when the component unmounts
      container.removeChild(script);
      container.removeChild(div);
    };
  }, []);

  return (
    <section className="mt-secondary lg:mt-primary overflow-hidden">
      <Container>
        <div className="border-b">
          <h1 className="text-2xl sm:text-3xl font-[700] text-lightFont mb-6 ">
            Our latest updates
          </h1>

          <div className="" id="elfsight-container"></div>
        </div>
      </Container>
    </section>
  );
};

export default LinkedIn;
