// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./DefaultSlider.css";

// import required modules
import { Navigation } from "swiper/modules";

const DefaultSlider = ({ data }) => {
  const x = [...data, ...data];
  return (
    <div className="relative">
      <Swiper
        navigation={true}
        speed={1000}
        slidesPerView={1}
        slidesPerGroup={1}
        breakpoints={{
          420: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          578: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          768: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
          992: {
            slidesPerView: 5,
            slidesPerGroup: 2,
          },

          1920: {
            slidesPerView: 8,
            slidesPerGroup: 2,
          },
          2560: {
            slidesPerView: 10,
            slidesPerGroup: 2,
          },
        }}
        modules={[Navigation]}
        className="default-slider "
      >
        {x?.map(({ image }, index) => (
          <SwiperSlide
            key={index}
            className="flex items-center justify-center "
          >
            <div className="flex items-center justify-center">
              <img src={image} alt="" className="h-32 w-32   object-contain" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default DefaultSlider;
