import { footerLinks } from "Constant/Footer";
import { Fragment } from "react";
import { Link } from "react-router-dom";
const LinkCol = ({ colStyle }) => {
  return (
    <div className={colStyle}>
      {footerLinks.map(({ name, url, mega, list }, index) => (
        <Fragment key={index}>
          {mega ? (
            <div key={index} className="flex flex-col gap-y-1.5 text-sm">
              <p className="text-red font-[600]">{name}</p>
              {list.map(({ name, url, link }, innerIndex) =>
                url ? (
                  <Link
                    key={innerIndex}
                    to={url}
                    className="text-[#4A4A4A] font-[400] hover:underline"
                  >
                    {name}
                  </Link>
                ) : (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="text-[#4A4A4A] font-[400] hover:underline"
                    href={link}
                  >
                    {name}
                  </a>
                )
              )}
            </div>
          ) : (
            <Link
              key={index}
              to={url}
              className="text-red font-[600]  hover:underline"
            >
              {name}
            </Link>
          )}
        </Fragment>
      ))}
      {/* {footerLinks.map(({ name, url, mega, list }, index) => (
        <Fragment key={index}>
          <Link to={url} className="text-red font-[600]  hover:underline">
            {name}
          </Link>
          {mega && (
            <div className="flex flex-col gap-y-1.5 text-sm">
              {list.map(({ name, url }, innerIndex) => (
                <Link
                  key={innerIndex}
                  to={url}
                  className="text-[#4A4A4A] font-[400] hover:underline"
                >
                  {name}
                </Link>
              ))}
            </div>
          )}
        </Fragment>
      ))} */}
    </div>
  );
};

export default LinkCol;
