import useFetch from "Hooks/useFetch";

export const footerLinks = [
  {
    name: "Home",
    url: "/",
  },
  // {
  //   name: "MDS Proactive",
  //   url: "/proactive",
  // },

  {
    name: "Company",
    mega: true,
    list: [
      {
        name: "About",
        url: "/about",
      },
      {
        name: "Contact",
        url: "/contact",
      },
      {
        name: "Partners",
        url: "/partners",
      },
      {
        name: "Careers",
        link: "https://careers.midisgroup.com/",
      },
      {
        name: "Security & Privacy",
        url: "/security",
      },
    ],
  },

  {
    name: "Success Stories",
    url: "/successStories",
  },

  // {
  //   name: "Resources",
  //   mega: true,
  //   title: "Our Media",
  //   list: [
  //     {
  //       name: "Blog",
  //       url: "/blog",
  //     },
  //     {
  //       name: "News/Events",
  //       url: "/news",
  //     },
  //   ],
  // },
];

export const Industry_solution = () => {
  const { data, serviceLoading, serviceError } = useFetch("industry-solutions");

  return [
    {
      name: "Industry solutions",
      title: "Our Industry Solutions",
      mega: true,
      list: data?.industrylist?.Solutionlist.map(({ title }) => ({
        title: title,
      })),
    },
  ];
};

export const ContactUs = () => {
  const { data, serviceLoading, serviceError } = useFetch("contact-us");

  return [
    {
      name: "Contact Us",
      title: "Contact Us",
      mega: true,
      list: data?.contactus?.list.map(
        ({ address, street, phone, email, location }) => ({
          address: address,
          street: street,
          phone: phone,
          email: email,
          location: location,
        })
      ),
    },
  ];
};
