import image from "Asset/images/logos/logo footer.png";
import { Link } from "react-router-dom";
const FooterLogo = () => {
  return (
    <Link to={"/"} className="w-60  mt-inner block">
      <img
        src={image}
        className="w-full object-contain"
        alt="live-route-logo"
      />
    </Link>
  );
};

export default FooterLogo;
