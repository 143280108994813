import Container from "Components/Container/Container";
import React from "react";
import SolutionHero from "./Hero/SolutionHero";
import SolutionSlider from "Components/Sliders/SolutionSlider";

const Solution = ({ data }) => {
  const heroData = {
    title: data?.title,
    subtitle: data?.subtitle,
  };
  return (
    <section className="bg-gray-200 mt-secondary lg:mt-primary py-primary">
      <Container>
        <SolutionHero data={heroData} />
        <SolutionSlider data={data.list} />
      </Container>
    </section>
  );
};

export default Solution;
