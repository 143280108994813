import React from "react";
import Container from "Components/Container/Container";
import fixImageUrl from "Helpers/FixImageUrl";
const Hero = ({ data }) => {
  const image = fixImageUrl(data.image);
  return (
    <section
      className="lg:h-[80vh] py-32 lg:py-0 background-class"
      style={{ backgroundImage: `url(${image})` }}
    >
      <Container className="h-full">
        <div className="h-full flex flex-col lg:items-center lg:justify-center lg:w-3/4 mx-auto font-segu text-white">
          <h1 className="text-3xl uppercase sm:text-4xl xl:text-5xl lg:text-center font-[700] mb-4">
            {data?.title}
          </h1>
          <a
            target="_blank"
            className="text-xl hover:underline"
            href="https://www.midisgroup.com/"
            rel="noreferrer"
          >
            {data?.subtitle}
          </a>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
