import Container from "Components/Container/Container";
import { Phone, EnvelopeSimpleOpen } from "@phosphor-icons/react";
const ContactInfo = ({ data }) => {
  const iconSize = 18;

  const locationHandler = (location) => {
    window.open(
      `https://www.google.com/maps?q=${location.lat},${location.lng}`,
      "_blank"
    );
  };
  return (
    <section className="mt-secondary">
      <Container>
        <h1 className="font-segu text-font text-3xl font-[700] mb-6">
          Contact Info
        </h1>
        <div className="flex flex-col lg:flex-row gap-24 font-segu capitalize">
          {data.map(({ address, street, phone, email, location }, index) => (
            <div key={index} className="flex-1 flex flex-col gap-y-3">
              <p className="text-2xl font-[500] text-red">{address}</p>

              <a
                href={location?.lat}
                target="_blank"
                rel="noreferrer"
                className="text-start mb-3 hover:underline"
              >
                {street}
              </a>
              <div className="flex flex-col gap-y-3">
                <span className="flex items-center gap-x-2">
                  <span className="w-6 h-6 p-1 bg-black rounded-full flex items-center justify-center">
                    <Phone size={iconSize} color="white" />
                  </span>
                  <a className="hover:underline" href={`tel:${phone.trim()}`}>
                    {phone}
                  </a>
                </span>

                <span
                  className={`flex items-center gap-x-2 hover:underline ${
                    email ? "" : "opacity-0"
                  }`}
                >
                  <span className="w-6 h-6 p-1 bg-black rounded-full flex items-center justify-center">
                    <EnvelopeSimpleOpen color="white" size={iconSize} />
                  </span>
                  <a href={`mailto:${email}`}>
                    {" "}
                    <span className="lowercase">{email}</span>
                  </a>
                </span>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default ContactInfo;
