import { useState, useEffect } from "react";

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const base_url = "https://liveroute.twalab.live/api";
  // const base_url = "https://phplaravel-1177998-4276233.cloudwaysapps.com/api";

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${base_url}/${url}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchBySlugData = async (slug) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${base_url}/${slug}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  return { data, loading, error, fetchData, fetchBySlugData }; // Return fetchData along with other values
};

export default useFetch;
