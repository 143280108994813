import Container from "Components/Container/Container";
import htmlToText from "Helpers/HtmlToText";
const ProActviveColumns = ({ data }) => {
  return (
    <section className="bg-gray-300 py-secondary lg:py-primary">
      <Container>
        <div>
          <h2 className="font-[700] text-font text-3xl mb-14">{data.title}</h2>
        </div>

        <div className="grid grid-cols-1 ss:grid-cols-2 xl:grid-cols-4 gap-10 md:gap-16 lg:gap-14">
          {data.list.map(({ image, description, title }, index) => (
            <div key={index} className="ss:w-3/4 md:w-full ">
              <div className="w-24 h-24 mx-auto bg-white p-5 flex items-center justify-center rounded-full mb-3">
                <img
                  className="w-full h-full object-contain"
                  src={image}
                  alt=""
                />
              </div>
              <div>
                <p className="font-segu font-[600] text-lightFont text-center text-sm">
                  {htmlToText(description || title)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default ProActviveColumns;
