import React from "react";
import Container from "Components/Container/Container";
import Button from "Components/Buttons/Button";
import { Link } from "react-router-dom";
import { CaretLeft } from "@phosphor-icons/react";
const SuccessStoriesHeader = ({ isInner }) => {
  return (
    <section className="pt-36 pb-12 lg:pt-40 lg:pb-16 bg-blue">
      <Container className={"h-full"}>
        <div className="h-full lg:w-1/2 flex flex-col justify-center font-segu text-white">
          <Link
            to={"/successStories"}
            className={`text-3xl lg:text-4xl ${
              isInner && "hover:underline"
            } font-[700] flex items-center gap-x-2`}
          >
            {isInner && <CaretLeft size={32} />}
            Success Stories
          </Link>

          <div className="mt-6  ">
            <Button to={"/contact"}>Send inquiry</Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SuccessStoriesHeader;
