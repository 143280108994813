import Container from "Components/Container/Container";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import htmlToText from "Helpers/HtmlToText";

// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

const BlogDetailed = () => {
  const { slug } = useParams();
  const { data, loading, error, fetchData } = useFetch(`blogs/${slug}`);

  useEffect(() => {
    fetchData();
  }, [slug]);

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  else if (data) {
    const blogData = data?.BlogsData[0];
    return (
      <main>
        <section className="bg-blue py-primary font-segu">
          <Container>
            <p className="text-4xl text-white">{blogData?.name}</p>
          </Container>
        </section>

        <section className="mt-secondary lg:-mt-secondary mb-primary">
          <Container>
            <div className="">
              <img
                className="w-full max-h-[500px] object-cover"
                src={blogData?.image}
                alt=""
              />
            </div>

            <div className="mt-secondary font-segu lg:w-3/4 mx-auto">
              <p className="text-2xl font-[600]">{blogData?.name}</p>
              <p className="text-xl italic">{blogData?.date}</p>
              <p className="text-xl mt-2">{blogData?.subTitle}</p>
              <p className="mt-4">{htmlToText(blogData?.description)}</p>
            </div>
          </Container>
        </section>
      </main>
    );
  }
};

export default BlogDetailed;
