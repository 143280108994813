import Container from "Components/Container/Container";

const WhyUs = ({ data }) => {
  const empty = data?.list.length === 0;
  return (
    <section
      className={`mt-secondary lg:mt-primary ${empty ? "hidden" : "block"}`}
    >
      <Container>
        <div className="font-segu mb-inner">
          <h2 className="text-3xl mb-2 font-[600]">{data.title}</h2>
          <p>{data.subtitle}</p>
        </div>

        <div className="grid grid-cols-1 ss:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 md:gap-16 lg:gap-20">
          {data?.list.map(({ image, title }, index) => (
            <div
              key={index}
              className="flex items-center flex-col text-center font-segu gap-2"
            >
              <div className="w-24 h-24 rounded-full border border-gray-300 p-4 flex items-center justify-center ">
                <img
                  src={image}
                  alt=""
                  className="max-w-full max-h-full object-contain"
                />
              </div>
              <div>
                <h3>{title}</h3>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default WhyUs;
