import Container from "Components/Container/Container";
import React from "react";
import htmlToText from "Helpers/HtmlToText";
import Button from "Components/Buttons/Button";
const Hero = ({ data }) => {
  return (
    <section
      className="h-[70vh] background-class"
      style={{ backgroundImage: `url(${data.image})` }}
    >
      <Container className={"h-full"}>
        <div className="h-full lg:w-1/2 flex flex-col justify-center font-segu text-white">
          <h1 className="text-4xl font-[700] mb-3">{data?.title}</h1>
          <p>{htmlToText(data.description)}</p>

          <div className="mt-6">
            <Button to={"/contact"}>Send inquiry</Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
