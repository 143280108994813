import { useState, useEffect } from "react";
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";
import { useParams } from "react-router-dom";

// inner componenys
import SuccessStoriesHeader from "./Components/SuccessStoriesHeader";
import SuccessStoryBody from "./Components/SuccessStoryBody";
import SucessStoryGallery from "./Components/SucessStoryGallery";
import OtherSuccessStoriesSlider from "./Components/OtherSuccessStoriesSlider";

const SuccessStories = () => {
  const { slug } = useParams();
  const { data, error, loading, fetchData } = useFetch(`success-story/${slug}`);

  useEffect(() => {
    fetchData();
  }, [slug]);

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  else if (data) {
    const successStory = data?.successStories;
    const galleryData = data?.successStories.gallery;
    const otherSuccessStories = data?.otherSuccessStories;
    return (
      <main>
        <SuccessStoriesHeader isInner={true} />
        <SuccessStoryBody data={successStory} />
        {galleryData.length !== 0 && <SucessStoryGallery data={galleryData} />}
        {/* <OtherSuccessStoriesSlider data={otherSuccessStories} /> */}
      </main>
    );
  }
};

export default SuccessStories;
