import htmlToText from "Helpers/HtmlToText";
import "./Style.css";
import Container from "Components/Container/Container";
import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Navigation } from "swiper/modules";
import { CaretRight, CaretLeft } from "@phosphor-icons/react";

const IndustrySolutionLanding = ({ data }) => {
  const slideRefs = useRef([]);
  const [maxHeight, setMaxHeight] = useState(0);
  const prevRefIndurstry = useRef(null);
  const nextRefIndurstry = useRef(null);

  // Declare swiper and setSwiper using useState
  const [swiper, setSwiper] = useState(null);

  useLayoutEffect(() => {
    if (
      slideRefs.current.length === 0 ||
      slideRefs.current.every((ref) => ref === null)
    ) {
      return;
    }

    const heights = slideRefs.current.map((slide) => {
      if (slide) {
        return slide.offsetHeight;
      }
      return 0;
    });

    const maxHeight = Math.max(...heights);

    setMaxHeight(maxHeight);
  }, [data]);

  useEffect(() => {
    // Ensure swiper is defined and navigation is available before updating
    if (swiper && swiper.navigation) {
      swiper.navigation.update();
    }
  }, [swiper]);

  return (
    <div className="my-secondary">
      <Container>
        <div className="flex flex-col">
          {data?.map(({ title, description, image, list }, index) => (
            <React.Fragment key={index}>
              <div
                key={index}
                className={`flex gap-4 xl:gap-6 flex-col lg:flex-row items-stretch`}
              >
                <div className="lg:flex-[2] w-full">
                  <img className="w-full object-cover" src={image} alt="" />
                </div>
                <div
                  className={`lg:flex-[3] flex flex-col justify-center font-segu py-6 ${
                    index % 2 === 0
                      ? "industry-container-right"
                      : "industry-container-left"
                  }`}
                >
                  <h4 className="text-2xl font-[700] text-red mb-2">{title}</h4>
                  <p className="font-segu">{htmlToText(description)}</p>
                </div>
              </div>

              <div className="relative z-[100] mt-14">
                <Swiper
                  spaceBetween={20}
                  slidesPerView={1}
                  slidesPerGroup={1}
                  navigation={{
                    prevEl: prevRefIndurstry.current,
                    nextEl: nextRefIndurstry.current,
                  }}
                  onSwiper={setSwiper}
                  breakpoints={{
                    420: {
                      slidesPerView: 2,
                      slidesPerGroup: 2,
                    },
                    768: {
                      slidesPerView: 3,
                      slidesPerGroup: 3,
                    },
                    992: {
                      slidesPerView: 4,
                      slidesPerGroup: 4,
                    },
                  }}
                  modules={[Autoplay, Navigation]}
                  className="industry-solution-slider"
                  speed={1000}
                >
                  {list?.map(({ id, title, text }, index) => (
                    <SwiperSlide
                      className="bg-gray-200 p-4 rounded-2xl transition-bg ease-in duration-300 hover:bg-[#c9041b62]"
                      key={index}
                      ref={(el) => {
                        slideRefs.current[index] = el;
                      }}
                      style={{
                        height: maxHeight ? `${maxHeight}px` : "auto",
                      }}
                    >
                      <p className="font-bold mb-3">{title}</p>
                      {text && <p>{htmlToText(text)}</p>}
                    </SwiperSlide>
                  ))}
                </Swiper>

                <button
                  ref={prevRefIndurstry}
                  className="absolute top-1/2 -translate-y-1/2 -left-8 z-[100] industry-button-prev flex items-center justify-center"
                >
                  <CaretLeft />
                </button>
                <button
                  ref={nextRefIndurstry}
                  className="absolute top-1/2 -translate-y-1/2 -right-8 z-[100] industry-button-next flex items-center justify-center"
                >
                  <CaretRight />
                </button>
              </div>
            </React.Fragment>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default IndustrySolutionLanding;
