import Container from "Components/Container/Container";
import htmlToText from "Helpers/HtmlToText";
const AboutLiveRoute = ({ data }) => {
  return (
    <section className="bg-[#013564] py-secondary">
      <Container className="lg:text-center">
        <div className="text-white mx-auto lg:w-3/4 font-segu">
          <h1 className="text-3xl font-[700] mb-2">{data.title}</h1>
          <p className="text-lg ">{data.subtitle}</p>
          <p>{htmlToText(data.description)}</p>
        </div>
      </Container>
    </section>
  );
};

export default AboutLiveRoute;
