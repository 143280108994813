import { Link } from "react-router-dom";
import htmlToText from "Helpers/HtmlToText";
import React, { useLayoutEffect, useRef, useState } from "react";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
// icon
import { CaretRight, CaretLeft, ArrowCircleRight } from "@phosphor-icons/react";

// style
import "./Slider.css";

const ServicesSlider = ({ data, isproavtive }) => {
  const swiperRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const handleMouseEnter = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.start();
    }
  };

  return (
    <div className="my-secondary lg:my-primary  ">
      <button
        ref={prevRef}
        className="absolute left-14 top-1/2 -translate-y-1/2 z-[100]  h-[64px] w-[24px] bg-[#ccc] hover:bg-[#ddd] flex items-center justify-center text-2xl text-red rounded-lg"
      >
        <CaretLeft />
      </button>
      <button
        ref={nextRef}
        className="absolute right-14 top-1/2 -translate-y-1/2 z-[100]  h-[64px] w-[24px] bg-[#ccc] hover:bg-[#ddd] flex items-center justify-center text-2xl text-red rounded-lg"
      >
        <CaretRight />
      </button>
      <div className="services-slider-container">
        <Swiper
          className="services-slider "
          navigation={{
            clickable: true,
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
          }}
          speed={1000}
          modules={[Autoplay, Navigation]}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          slidesPerView={1}
          breakpoints={{
            1200: {
              slidesPerView: 1.3,
            },
            1920: {
              slidesPerView: 2,
            },
            2560: {
              slidesPerView: 3,
            },
          }}
          loop={true}
          effect="fade"
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        >
          {data?.map((sol, index) => (
            <SwiperSlide
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              key={index}
            >
              <div className="overflow-hiddesn flex flex-col md:flex-row gap-y-6 md:gap-x-14  md:border-l md:border-grey px-[3rem]  md:px-6 lg:mx-14">
                <div
                  style={{
                    backgroundImage: `url(${sol.image})`,
                    objectPosition: "right",
                    backgroundPosition: "right",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  className="w-[16rem] h-[16rem] md:w-[12rem] md:h-[12rem] lg:min-w-[20rem] lg:min-h-[20rem] flex items-center justify-center rounded-full"
                ></div>

                <div className="lg:w-[60%] flex flex-col justify-center">
                  <h2 className="text-red font-segu font-[500] text-2xl lg:text-3xl sm:w-3/4">
                    {sol.title}
                  </h2>
                  {!isproavtive && (
                    <p className="text-lightFont font-segu text-xl mt-1 lg:w-3/4 xxl:w-full">
                      {htmlToText(sol.subtitle)}
                    </p>
                  )}
                  {isproavtive && (
                    <p className="text-lightFont font-segu text-xl mt-1 lg:w-3/4 xxl:w-full">
                      {htmlToText(sol.description)}
                    </p>
                  )}
                  <ul
                    className={`hidden md:grid ml-2 list-disc list-outside my-3 gap-x-10 gap-5 mt-4 font-segu text-lightFont font-[500] max-w-full ${
                      sol?.list?.length > 8
                        ? "grid-cols-3 w-max"
                        : "grid-cols-2 w-max"
                    }`}
                  >
                    {sol?.list?.map(({ title }, index) => (
                      <li className="font-segu flex-1 text-font" key={index}>
                        {title}
                      </li>
                    ))}
                  </ul>
                  {sol.slug && (
                    <span className="flex items-center gap-x-1 mt-4">
                      <ArrowCircleRight
                        size={24}
                        weight="fill"
                        color="#c9041a"
                      />
                      <Link
                        className="text-red text-sm uppercase"
                        to={`/services/${sol.slug}`}
                      >
                        Read More
                      </Link>
                    </span>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ServicesSlider;
