import React from "react";
import fixImageUrl from "Helpers/FixImageUrl";
import Container from "Components/Container/Container";

const Hero = ({ data }) => {
  const image = fixImageUrl(data.image);
  return (
    <section
      className="h-[40vh] background-class"
      style={{ backgroundImage: `url(${image})` }}
    >
      <Container className={"h-full"}>
        <div
          className={
            "h-full flex items-center text-white text-4xl font-[700] font-segu"
          }
        >
          <h1>{data.title}</h1>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
