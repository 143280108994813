import Container from "Components/Container/Container";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";
// inner components
import Hero from "./Sections/Hero";
import AboutTheServices from "./Sections/AboutTheServices";
import ServicesFeature from "./Sections/ServicesFeature";
import ServicesSlider from "Components/Sliders/ServicesSlider";
import WhyUs from "./Sections/WhyUs";
import ContactBox from "Components/Boxes/ContactBox";
const Services = () => {
  const { slug } = useParams();
  const { data, loading, error, fetchData } = useFetch(`services/${slug}`);

  useEffect(() => {
    fetchData();
  }, [slug]);

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  if (data) {
    const servicesData = data?.servicedata;
    const heroData = servicesData?.hero;
    const aboutServices = servicesData?.about_the_service;
    const servicesFeature = servicesData?.services_feature;
    const whyUs = servicesData?.why_us;
    const otherServices = servicesData?.other_services;

    return (
      <main>
        <Hero data={heroData} />
        <AboutTheServices data={aboutServices} />
        {servicesFeature?.list.length !== 0 && (
          <div className="bg-gray-300 py-secondary ">
            <div className="services-slider-container">
              <h2 className=" mb-inner text-3xl font-segu md:text-center ">
                {servicesFeature.title}
              </h2>
              <ServicesFeature data={servicesFeature} />
            </div>
          </div>
        )}
        <WhyUs data={whyUs} />
        <ContactBox />
        {/* <div className="my-secondary lg:my-primary services-slider-container">
          <h3 className="lg:text-center font-segu text-3xl font-[700] text-lightFont">
            Other Services
          </h3>
          <ServicesSlider data={otherServices} />
        </div> */}
      </main>
    );
  }
};

export default Services;
