import Container from "Components/Container/Container";
import image from "Asset/images/aboutus/live-22.jpg";
const AboutMidis = ({ data, valuesData }) => {
  return (
    <section className="bg-[#013564] py-24">
      <Container>
        <div
          style={{ backgroundImage: `url(${image})` }}
          className="font-segu text-white background-class px-8 py-16 rounded-2xl"
        >
          <div className="lg:w-[60%]">
            <h4 className="text-3xl font-[700] mb-4">{data.title}</h4>

            <div dangerouslySetInnerHTML={{ __html: data?.description }} />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AboutMidis;
