import Container from "Components/Container/Container";
import { Link } from "react-router-dom";
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

const Blog = () => {
  const { data, error, loading } = useFetch("blogs");

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  if (data) {
    const blogData = data?.BlogsData;
    return (
      <main className="">
        <section className="py-primary bg-blue font-segu text-4xl text-white">
          <Container>
            <h2>Our Blog</h2>
          </Container>
        </section>

        <Container>
          <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14 lg:gap-6 my-secondary lg:my-primary">
            {blogData.map(({ name, slug, date, image }, index) => {
              return (
                <Link to={`${slug}`} key={index}>
                  <div>
                    <div>
                      <img
                        src={image}
                        alt=""
                        className="h-full w-full aspect-[2/1]"
                      />
                    </div>
                    <div>
                      <span className="text-lightGreen mt-3 block">{date}</span>
                      <p className="text-xl font-semibold text-blueFont">
                        {name}
                      </p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </section>
        </Container>
      </main>
    );
  }
  return <main></main>;
};

export default Blog;
