import Container from "Components/Container/Container";
import htmlToText from "Helpers/HtmlToText";

const ServicesHero = ({ data }) => {
  return (
    <div className="">
      <Container>
        <div className="lg:text-center lg:w-3/4 mx-auto font-segu">
          <h2 className="text-4xl text-red font-[500] ">{data.title}</h2>
          <p className="text-lightFont font-[500] mt-2 sm:text-lg">
            {htmlToText(data.subtitle)}
          </p>
        </div>
      </Container>
    </div>
  );
};

export default ServicesHero;
