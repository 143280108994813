import ServicesHero from "./Components/ServicesHero";
import ServicesSlider from "Components/Sliders/ServicesSlider";

const Services = ({ data }) => {
  const heroData = {
    title: data.title,
    subtitle: data.subtitle,
  };
  return (
    <section className="mt-secondary lg:mt-primary ">
      <div className="services-slider-container">
        <ServicesHero data={heroData} />
      </div>
      <div className="relative">
        <ServicesSlider data={data?.servicesList} />
      </div>
    </section>
  );
};

export default Services;
