import {
  InstagramLogo,
  FacebookLogo,
  LinkedinLogo,
  TwitterLogo,
} from "@phosphor-icons/react";

const MobileContactInfro = () => {
  const iconStyle = {
    size: 22,
    color: "white",
  };

  const iconsBoXStyle = "bg-red p-1.5 rounded-full";
  return (
    <div className="flex mb-8 flex-col gap-y-2 items-center justify-between z-[10000]">
      <div>
        <p className="font-segu text-[#8e8a97] font-[600]">
          Part of MIDIS Group
        </p>
      </div>
      <div className="flex items-center gap-x-2">
        {/* <a className={iconsBoXStyle} href="">
          <InstagramLogo size={iconStyle.size} color={iconStyle.color} />
        </a>

        <a className={iconsBoXStyle} href="">
          <FacebookLogo size={iconStyle.size} color={iconStyle.color} />
        </a> */}

        <a
          className={iconsBoXStyle}
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/company/liveroute/"
        >
          <LinkedinLogo size={iconStyle.size} color={iconStyle.color} />
        </a>

        {/* <a className={iconsBoXStyle} href="">
          <TwitterLogo size={iconStyle.size} color={iconStyle.color} />
        </a> */}
      </div>
    </div>
  );
};

export default MobileContactInfro;
