import Container from "Components/Container/Container";
import { Link } from "react-router-dom";
import { ArrowCircleRight } from "@phosphor-icons/react";

const NewsLanding = ({ data }) => {
  return (
    <section className="my-primary">
      <Container>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-20 gap-x-10">
          {data.map(({ name, slug, date, image }, index) => (
            <Link to={`${slug}`} key={index} className="group">
              <div>
                <div>
                  <img
                    src={image}
                    alt=""
                    className="h-full w-full aspect-[2/1] object-cover"
                  />
                </div>
                <div>
                  <span className="text-lightGreen mt-3 block">{date}</span>
                  <p className="text-xl font-semibold text-blueFont">{name}</p>
                </div>
              </div>

              <button className="mt-2 group-hover:underline flex items-center gap-x-1">
                <ArrowCircleRight size={24} weight="fill" color="#c9041a" />
                Read More{" "}
              </button>
            </Link>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default NewsLanding;
