import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// UI
import Container from "Components/Container/Container";
// inner components
import Logo from "./Components/Logo/Logo";
import ContactInfro from "./Components/ContactInfo/ContactInfro";
import DesktopNav from "./Components/Navigation/DesktopNav";
import MobileButton from "./Components/Navigation/MobileButton";

const Header = () => {
  const location = useLocation();
  const [isHome, setIsHome] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
      setIsHome(true);
    } else {
      setIsHome(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScroll(scrollTop > 0);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`w-full transition-all ease-in duration-300 ${
        isScroll && !isHome
          ? "sticky top-0 bg-white pb-3 border-b border-font"
          : ""
      } ${
        isScroll && isHome
          ? "bg-blue sticky top-0 py-3"
          : "absolute min-h-min pt-5"
      }  ${
        isHome ? "absolute min-h-min pt-2" : "relative pt-2"
      } inset-0 z-[100000]`}
    >
      <Container>
        <div
          className={`flex items-center justify-between ${
            isHome ? "border-b " : ""
          }`}
        >
          <Logo isScroll={isScroll} isHome={isHome} />
          <div className={`hidden xl:flex  ${isScroll ? "" : "flex-col"}`}>
            <ContactInfro isScroll={isScroll} isHome={isHome} />
            <DesktopNav isHome={isHome} />
          </div>

          <MobileButton />
        </div>
      </Container>
    </header>
  );
};

export default Header;
