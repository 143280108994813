import React from "react";
import htmlToText from "Helpers/HtmlToText";
import Container from "Components/Container/Container";

const Values = ({ data }) => {
  return (
    <div className="bg-[#013564] pb-36">
      <Container>
        <div className="flex flex-col lg:flex-row gap-14 lg:gap-24">
          {data.map(({ title, description }, index) => (
            <div
              className="flex-1 rounded-xl  p-8 font-segu text-white bg-[#1E4D79]"
              key={index}
            >
              <h4 className="mb-3">
                <span className="text-3xl font-[400]">
                  {title.split(" ")[0]}
                </span>
                <br />
                <span className="text-4xl font-[700]">
                  {title.split(" ")[1]}
                </span>
              </h4>
              <p className="text-m w-3/4">{htmlToText(description)}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Values;
