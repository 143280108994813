import React from "react";
import htmlToText from "Helpers/HtmlToText";

const ModalContent = ({ data }) => {
  return (
    <div className="text-white font-segu lg:w-1/2 ">
      <h1 className="font-[900] text-3xl mb-2">{data.title}</h1>

      <p className="text-lg">{htmlToText(data.description)}</p>
    </div>
  );
};

export default ModalContent;
