import React from "react";
import ServicesSlider from "Components/Sliders/ServicesSlider";
import Container from "Components/Container/Container";

const Approach = ({ data }) => {
  return (
    <section className="my-secondary lg:my-primary">
      <Container>
        <h2 className="text-3xl font-[700] font-segu capitalize text-font">
          {data?.title}
        </h2>
      </Container>
      <ServicesSlider isproavtive={true} data={data?.list} />
    </section>
  );
};

export default Approach;
