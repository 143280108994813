import "../../Header.css";
import HeaderLinks from "Constant/Header";
import { Link } from "react-router-dom";
import Container from "Components/Container/Container";
import {
  CaretDown,
  CaretLeft,
  CaretRight,
  DotOutline,
} from "@phosphor-icons/react";
import { useState, useEffect, Fragment } from "react";
import MobileContactInfro from "../ContactInfo/MobileContact";
import { set } from "lodash";

const MobileNav = ({ isMobileNavOpen, handleMobileNav }) => {
  const links = HeaderLinks();
  const buttonStyle =
    "text-white text-xl block w-full text-start border-b border-white pb-2";

  // State to manage which list is expanded
  const [expandedList, setExpandedList] = useState(null);

  // Function to toggle expansion of list
  const toggleExpansion = (index) => {
    if (expandedList === index) {
      setExpandedList(null);
    } else {
      setExpandedList(index);
    }
  };

  const handleSubNavClosing = () => {
    handleMobileNav();
    toggleExpansion();
  };

  return (
    <div
      className={`absolute bg-black w-[100vw] h-[100dvh] left-0 top-0 xl:hidden transition ease-in duration-300  ${
        isMobileNavOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <ul
        className={`flex flex-col h-full transition ease-in duration-500 ${
          isMobileNavOpen ? "scale-1 opacity-1" : "scale-[0.4] opacity-0"
        }`}
      >
        <Container className="flex h-full flex-col justify-between gap-y-3">
          <div className="flex flex-col mt-[18vh] flex-1 gap-y-6 relatvie ">
            {links?.map(({ name, url, list, mega }, index) =>
              !mega ? (
                <Link
                  onClick={handleMobileNav}
                  key={index}
                  to={url}
                  className={buttonStyle}
                >
                  {name}
                </Link>
              ) : (
                <Fragment key={index}>
                  <button
                    className={`${buttonStyle} flex items-center justify-between`}
                    onClick={() => toggleExpansion(index)}
                  >
                    {name}
                    <span
                      className={`animate-pulse ${
                        expandedList === index ? "transform rotate-180" : ""
                      }`}
                    >
                      <CaretRight size={26} />
                    </span>
                  </button>
                  <div
                    className={`h-[100vh] z-[1000] w-[100vw] inset-0 flex flex-col mt-[18vh] bg-black absolute transition ease-in duration-300 ${
                      expandedList === index
                        ? "translate-x-0 opacity-100"
                        : "translate-x-full opacity-0"
                    }`}
                  >
                    <Container>
                      <button
                        onClick={toggleExpansion}
                        className="text-start w-full text-white text-2xl border-b border-white pb-2 mb-6 flex items-center gap-x-2"
                      >
                        <CaretLeft size={26} />
                        Back
                      </button>
                      <ul className="flex flex-col gap-y-3">
                        {list?.map(({ name, url }, innerIndex) => (
                          <Link
                            onClick={handleSubNavClosing}
                            to={url}
                            key={innerIndex}
                            className="text-white flex items-center gap-x-1"
                          >
                            <span className="animate-pulse">
                              {" "}
                              <DotOutline color="white" size={32} />
                            </span>
                            {name}
                          </Link>
                        ))}
                      </ul>
                    </Container>
                  </div>
                </Fragment>
              )
            )}
          </div>

          <MobileContactInfro />
        </Container>
      </ul>
    </div>
  );
};

export default MobileNav;
