import sucessImage from "Asset/images/contactbox/success-storie.jpg";
import contactImage from "Asset/images/contactbox/contact.jpg";
import { Link } from "react-router-dom";
import Container from "Components/Container/Container";
import Button from "Components/Buttons/Button";

const ContactBox = () => {
  return (
    <div className="flex flex-col lg:flex-row my-secondary lg:my-primary">
      <div
        className="flex-1 background-class py-20 text-white font-segu"
        style={{
          backgroundImage: `url(${sucessImage})`,
        }}
      >
        <Container>
          <h4 className="font-[700] text-3xl">Customer</h4>
          <p className="text-5xl">Success Story</p>

          <div className="mt-6">
            <Button to={"/successStories"}>Read More</Button>
          </div>
        </Container>
      </div>
      <div
        className="flex-1 background-class py-20 text-white font-segu"
        style={{
          backgroundImage: `url(${contactImage})`,
        }}
      >
        <Container>
          <h4 className="font-[700] text-3xl">For more Info</h4>
          <p className="text-5xl">Let's talk</p>
          <div className="mt-6">
            <Button to={"/contact"}>Contact Us</Button>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ContactBox;
