import Container from "Components/Container/Container";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./style.css";

import htmlToText from "Helpers/HtmlToText";
// import required modules
import { Navigation, Pagination } from "swiper/modules";

const FeatureMobile = ({ data }) => {
  return (
    <section className="relative slider-parent">
      <div className="slider-container relative">
        <div className="font-segu text-white top-0 left-0 bg-blue w-full z-50 py-8 ">
          <Container>
            <h2 className="font-[700] uppercase text-xl ss:text-2xl lg:text-2xl xxl:text-3xl">
              {data.title}
            </h2>
            <p className="ss:text-lg font-[300] mt-1">{data.subtitle}</p>
          </Container>
        </div>
        <Swiper
          className="feature-slider"
          navigation={true}
          speed={1000}
          modules={[Navigation]}
        >
          {data.list.map(({ image, title, text, description, list }, index) => (
            <SwiperSlide key={index}>
              <div
                key={index}
                className="flex py-16 lg:py-24 xxl:py-32 w-full  items-center  panel-background "
                style={{ backgroundImage: `url(${image})` }}
              >
                <Container>
                  <div className="lg:w-3/4 xl:w-1/2 text-white font-segu ">
                    <h1 className="lg:hidden text-3xl mb-2">{title}</h1>
                    <h1 className="hidden lg:block pl-6">
                      <span className="text-3xl xxl:text-4xl font-[400]">
                        {title.split(" ")[0]}
                      </span>
                      <br />
                      <span className="text-6xl font-[700]">
                        {title.split(" ")[1]}
                      </span>
                    </h1>
                    <div className="lg:border-l lg:border-white lg:pl-6">
                      {" "}
                      <p className="mt-4">{htmlToText(description)}</p>
                      {list.length > 0 && (
                        <ul className="mt-6 list-disc list-outside">
                          {list.map(({ title }, index) => (
                            <li key={index}>{title}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </Container>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default FeatureMobile;
