// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";
// inner components
import Hero from "./Sections/Hero/Hero";
import WhatIsProActive from "./Sections/WhatIsProactive/WhatIsProActive";
import ProActviveColumns from "./Sections/proActvieDelivers/ProActviveColumns";
import Modal from "./Sections/Modal/Modal";
import Approach from "./Sections/Approach/Approach";

const ProActvie = () => {
  const { data, loading, error } = useFetch("proactive");
  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  if (data) {
    const proActiveData = data?.proactive_data;
    const heroData = proActiveData?.hero;
    const whatIsProactiveData = proActiveData?.what_is_proActive;
    const proActvieDeliversData = proActiveData?.proActvieDelivers;
    const modalData = proActiveData?.modals;
    const approachData = proActiveData?.approach;
    const benefitsData = proActiveData?.benefits;

    return (
      <main>
        <Hero data={heroData} />
        <WhatIsProActive data={whatIsProactiveData} />
        <ProActviveColumns data={proActvieDeliversData} />
        <Modal data={modalData} />
        <Approach data={approachData} />
        <ProActviveColumns data={benefitsData} />
      </main>
    );
  }
};

export default ProActvie;
