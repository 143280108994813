import React from "react";
import Container from "Components/Container/Container";
import fixImageUrl from "Helpers/FixImageUrl";
import htmlToText from "Helpers/HtmlToText";
import { Link } from "react-router-dom";
import { ArrowCircleRight } from "@phosphor-icons/react";
const About = ({ data }) => {
  const image = fixImageUrl(data.image);
  return (
    <section
      className="background-class py-primary xl:py-primary mb-secondary lg:mb-primary"
      style={{ backgroundImage: `url(${image})` }}
    >
      <Container>
        <div className="lg:w-1/2 lg:ml-auto font-segu">
          <h2 className="text-2xl font-[700] text-font mb-2">{data.title}</h2>
          <h6 className="text-4xl font-[900] text-red">{data.subtitle}</h6>
          <p>{htmlToText(data.description)}</p>

          <span className="flex items-center gap-x-1 mt-3 ">
            <Link
              className="text-font text-sm uppercase hover:underline "
              to={"about"}
            >
              Read More
            </Link>
            <ArrowCircleRight size={22} weight="fill" color="black" />
          </span>
          <div className="mt-8 flex flex-col gap-6 font-segu">
            {data.values.map(({ title, description }, index) => (
              <div key={index}>
                <h6 className="text-2xl font-[500] text-red">{title}</h6>

                <p className="text-sm text-font">{htmlToText(description)}</p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default About;
