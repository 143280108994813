import HeaderLinks from "Constant/Header";
import { Fragment } from "react";
import { Link } from "react-router-dom";

const SolutionCol = ({ colStyle }) => {
  const links = HeaderLinks();
  const solutionLinks = links.filter((link) => link.name === "Solutions");
  return (
    <div className={colStyle}>
      {solutionLinks?.map(({ name, mega, list, url }, index) => (
        <Fragment key={index}>
          <p to={url} className="text-red font-[600] ">
            {name}
          </p>
          {mega && (
            <div className="flex flex-col gap-y-1.5 text-sm">
              {list?.map(({ name, url }, innerIndex) => (
                <Link
                  key={innerIndex}
                  to={url}
                  className="text-[#4A4A4A] font-[400] hover:underline"
                >
                  {name}
                </Link>
              ))}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default SolutionCol;
