import React from "react";
import Container from "Components/Container/Container";
import htmlToText from "Helpers/HtmlToText";
const WhatIsProActive = ({ data }) => {
  return (
    <section className="py-secondary lg:py-primary">
      <Container>
        <div className="flex flex-col lg:items-center lg:text-center font-segu lg:w-3/4 mx-auto">
          <h2 className="font-[900] text-red text-3xl mb-2">{data?.title}</h2>
          <p className="text-lg text-font">{htmlToText(data?.description)}</p>
        </div>
      </Container>
    </section>
  );
};

export default WhatIsProActive;
