import React, { useEffect, useRef } from "react";
import Hero from "./Sections/Hero";
import ContactInfo from "./Sections/ContactInfo";
import Form from "./Sections/Form";
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

const Contact = () => {
  const { data, loading, error } = useFetch("contact-us");
  const formRef = useRef(null); // Create a reference for the form element

  useEffect(() => {
    // Function to scroll to the form
    const scrollToForm = () => {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    };

    // Scroll to the form when data is loaded
    if (!loading && !error && data) {
      scrollToForm();
    }
  }, [data, loading, error]);

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  if (data) {
    const contactData = data?.contactus;
    const heroData = contactData?.hero;
    const contactListData = contactData?.list;
    return (
      <main>
        <Hero data={heroData} />
        <ContactInfo data={contactListData} />
        <div ref={formRef}>
          <Form /> {/* Pass the ref to the Form component */}
        </div>
      </main>
    );
  }
};

export default Contact;
