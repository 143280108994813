import React from "react";
import Container from "Components/Container/Container";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../../Components/Sliders/DefaultSlider.css";
// import required modules
import { Pagination, Navigation } from "swiper/modules";
const SucessStoryGallery = ({ data }) => {
  return (
    <section className="mt-primary bg-gray-300 py-primary ">
      <Container>
        <h2 className="text-3xl font-[700] text-lightFont mb-secondary font-segu">
          Success Story Gallery
        </h2>
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          navigation={true}
          spaceBetween={14}
          slidesPerView={2}
          slidesPerGroup={2}
          breakpoints={{
            578: {
              slidesPerView: 3,
              slidesPerGroup: 4,
            },
            768: {
              slidesPerView: 4,
              slidesPerGroup: 3,
            },
            992: {
              slidesPerView: 3,
              slidesPerGroup: 2,
            },
          }}
          modules={[Pagination, Navigation]}
          className="default-slider"
        >
          {data?.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt="" className="h-full w-full object-cover" />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  );
};

export default SucessStoryGallery;
